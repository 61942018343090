import React, { Fragment } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import gsap from "scripts/gsap/gsap-core.js";

import css from "./index.module.scss";

export default function FloatingImage({
  file = "",
  sizes = [],
  loading = "lazy",
  className = "",
}) {
  let webpSizes = "";
  let pngSizes = "";

  for (let size of sizes){
    webpSizes += `${file}-${size}w.webp ${size}w,`;
    pngSizes += `${file}-${size}w.png ${size}w,`;
  }


  return <div className={`${css.floatingImage} ${className}`}>
    <div className={css.floatingImageInner}>
      <div className={css.floatingImagePicture}>
        <picture>
          <source srcSet={webpSizes} type="image/webp"/>
          <source srcSet={pngSizes} type="image/png"/>
          <img loading={loading} src={`${file}.png`} alt="" className={css.floatingElement}/>
        </picture>
      </div>
      <div className={css.floatingShadow}>
        <img src="/images/floatingImages/shadow.svg" alt="" loading={loading}/>
      </div>
    </div>
  </div>;
}
