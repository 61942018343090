import React, { Fragment } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import gsap from "scripts/gsap/gsap-core.js";

import css from "./index.module.scss";

export default function RevealedText({
  texts = [""],
  color = "#ffffff",
  className = "",
}) {
  const refs = useRef([]);

  useEffect(() => {
    let master = gsap.timeline({paused: true});

    if (refs){
      let tls = [];
      for (let ref of refs.current){
        if (ref){
          let tl = gsap.timeline();
          tl.set(`.${css.revealingHider}`, {visibility: "visible"});
          tl.set(`.${css.revealingText}`, {visibility: "visible"});
          tl.fromTo(ref.querySelector(`.${css.revealingHider}`), {
            translateX: "120%",
          }, {
            translateX: "0",
            duration: 0.5,
            ease: "sine.inOut",
          });
          tl.set(ref.querySelector(`.${css.revealingText}`), {opacity: 1});
          tl.to(ref.querySelector(`.${css.revealingHider}`), {
            translateX: "-120%",
            duration: 0.5,
            ease: "sine.inOut",
          });
          tls.push(tl);
        }
      }

      for (let i = 0; i < tls.length; i++){
        master.add(tls[i], i * 0.100);
      }
    }


    var observer = new IntersectionObserver(function(entries) {
      let time = 0;
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          master.resume();
        }
      });
    }, { threshold: [1] });
    observer.observe(refs.current[0]);

    return () => {
      master.kill();
    };
  }, [refs]);

  return <>
    {texts.map((text) => <div key={text} className={`${css.revealing} ${className}`} ref={(element) => refs.current.push(element)}>
      <div className={`initialInvis ${css.revealingText}`}>{text}</div>
      <div className={`initialInvis ${css.revealingHider}`} style={{background: color}}></div>
    </div>)}
  </>;
}
