import Link from "next/link";
import React, {forwardRef} from "react";
import css from "./index.module.scss";

export default forwardRef(({
  href,
}, ref) => {
  return <Link href={href}>
    <a className={`custom ${css.arrow}`}>
      <img src="/images/misc/arrow-down.svg" alt="arrow down"/>
    </a>
  </Link>;
});
