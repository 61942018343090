import React from "react";
import css from "./index.module.scss";
import GenericLayout from "layouts/generic";
import LogoAnimatedSvg from "/public/images/logos/deviatelabs/animatedSeal.svg";
import RevealedText from "@/components/revealedText";
import FloatingImage from "@/components/floatingImage";
import BobbingArrow from "@/components/bobbingArrow";


export default function Homepage() {
  return (
    <GenericLayout
      title="&#x1F4C8; Innovative Growth Marketing Agency (Trusted)"
      description="We are a cutting-edge growth marketing agency helping our clients achieve unprecedented results through growth hack marketing."
      schema={ {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Deviate Labs",
        "legalName": "Deviate Labs Llc",
        "url": "https://deviatelabs.com",
        "logo": "https://deviatelabs.com//images/logos//logo.svg",
        "foundingDate": "2014",
        "founders": [
          {
            "@type": "Person",
            "name": "Chad Riddersen",
          }, {
            "@type": "Person",
            "name": "Raymond Fong",
          },
        ],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "8605 Santa Monica Blvd, Ste 93568",
          "addressLocality": "Los Angeles",
          "addressRegion": "CA",
          "postalCode": "90069",
          "addressCountry": "USA",
        },
        "contactPoint": {
          "@type": "ContactPoint",
          "contactType": "customer support",
          "telephone": "[+888-733-8609]",
          "email": "hello@deviatelabs.com",
        },
        "sameAs": ["https://www.facebook.com/deviatelabs", "https://www.linkedin.com/company/deviatelabs"],
      }}
    >

      <section className={`section ${css.fold}`}>
        <div className={`sectionInner ${css.sectionInner}`}>
          <h1>
            <div>The <span>Growth</span></div>
            <div><span>Marketing</span></div>
            <div>Agency</div>
          </h1>
          <BobbingArrow href="#question"/>
        </div>

        <div className={css.svgWrapper}>
          <LogoAnimatedSvg/>
        </div>
      </section>

      <section className={`section ${css.floater} ${css.question}`} id="question">
        <div className="sectionInner">
          <h2 className={css.h2}>
            <RevealedText
              texts={[
                "What do the following",
                "companies have in",
                "common?",
              ]}
              color="var(--purple)"
            />
          </h2>
        </div>
      </section>

      <section className={`section ${css.floater} ${css.yellow}`}>
        <div className="sectionInner">
          <h2 className={css.h2}>
            <RevealedText
              texts={[
                "A startup acquired",
                "for $1 billion",
              ]}
              color="#ffffff"
            />
          </h2>
          <FloatingImage file="/images/floatingImages/razor/razor" sizes={["180", "384", "640"]} loading="lazy" className={css.floatingImage}/>
        </div>
      </section>

      <section className={`section ${css.floater} ${css.red}`}>
        <div className="sectionInner">
          <h2 className={css.h2}>
            <RevealedText
              texts={[
                "A baby product",
                "company",
                "from Shark Tank",
              ]}
              color="#ffffff"
            />
          </h2>
          <FloatingImage file="/images/floatingImages/shark-tank/shark-tank" sizes={["180", "384", "640"]} loading="lazy" className={css.floatingImage}/>
        </div>
      </section>

      <section className={`section ${css.floater} ${css.pink}`}>
        <div className="sectionInner">
          <h2 className={css.h2}>
            <RevealedText
              texts={[
                "A celebrity-owned",
                "CPG startup",
              ]}
              color="#ffffff"
            />
          </h2>
          <FloatingImage file="/images/floatingImages/hello-bello/hello-bello" sizes={["180", "384", "640"]} loading="lazy" className={css.floatingImage}/>
        </div>
      </section>

      <section className={`section ${css.floater} ${css.green}`}>
        <div className="sectionInner">
          <h2 className={css.h2}>
            <RevealedText
              texts={[
                "The world's leading",
                "ed tech company",
              ]}
              color="#ffffff"
            />
          </h2>
          <FloatingImage file="/images/floatingImages/smart/smart" sizes={["180", "384", "640"]} loading="lazy" className={css.floatingImage}/>
          <FloatingImage file="/images/floatingImages/smart/smart-gray" sizes={["180", "384", "640"]} loading="lazy" className={css.floatingImage}/>
        </div>
      </section>

      <section className={`section ${css.floater} ${css.cyan}`}>
        <div className="sectionInner">
          <h2 className={css.h2}>
            <RevealedText
              texts={[
                "A Grammy-nominated",
                "music artist",
              ]}
              color="#ffffff"
            />
          </h2>
          <FloatingImage file="/images/floatingImages/grammy/grammy" sizes={["180", "384", "640"]} loading="lazy" className={css.floatingImage}/>
        </div>
      </section>

      <section className={`section ${css.floater} ${css.red}`}>
        <div className="sectionInner">
          <h2 className={css.h2}>
            <RevealedText
              texts={[
                "A top-rated",
                "WordPress host",
              ]}
              color="#ffffff"
            />
          </h2>
          <FloatingImage file="/images/floatingImages/automattic/automattic" sizes={["180", "384", "640"]} loading="lazy" className={css.floatingImage}/>
        </div>
      </section>


      <section className={`section ${css.answer}`}>
        <div className="sectionInner">
          <h2 className={css.h2}>The answer is...</h2>
          <RevealedText
            className={css.revealedLogo}
            texts={[<img key="0" src="/images/logos/deviatelabs/sealText.svg" alt="" loading="lazy"/>]}
            color="var(--purple)"
          />
        </div>
      </section>

      <section className={`section ${css.partnerSection}`}>
        <div className="sectionInner">
          <h2 className="underlined">
            <span>Our Work Has</span>
            <span>Been Featured On</span>
          </h2>
          <div className={css.partners}>
            <picture>
              <source srcSet="/images/logos/abcNews/abcNews.webp" type="image/webp"/>
              <source srcSet="/images/logos/abcNews/abcNews.png" type="image/png"/>
              <img width="127" height="44" src="/images/logos/abcNews/abcNews.png" alt="ABC News" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/cbs/cbs.webp" type="image/webp"/>
              <source srcSet="/images/logos/cbs/cbs.png" type="image/png"/>
              <img width="113" height="31" src="/images/logos/cbs/cbs.png" alt="CBS" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/readWrite/readWrite.webp" type="image/webp"/>
              <source srcSet="/images/logos/readWrite/readWrite.png" type="image/png"/>
              <img width="128" height="22" src="/images/logos/readWrite/readWrite.png" alt="Read Write" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/sharkTank/sharkTank.webp" type="image/webp"/>
              <source srcSet="/images/logos/sharkTank/sharkTank.png" type="image/png"/>
              <img width="143" height="28" src="/images/logos/sharkTank/sharkTank.png" alt="Shark Tank" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/ventureBeat/ventureBeat.webp" type="image/webp"/>
              <source srcSet="/images/logos/ventureBeat/ventureBeat.png" type="image/png"/>
              <img width="168" height="21" src="/images/logos/ventureBeat/ventureBeat.png" alt="Venture Beat" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/techCoctail/techCoctail.webp" type="image/webp"/>
              <source srcSet="/images/logos/techCoctail/techCoctail.png" type="image/png"/>
              <img width="125" height="42" src="/images/logos/techCoctail/techCoctail.png" alt="Tech Cocktail" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/univision/univision.webp" type="image/webp"/>
              <source srcSet="/images/logos/univision/univision.png" type="image/png"/>
              <img width="152" height="30" src="/images/logos/univision/univision.png" alt="Univision" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/productHunt/productHunt.webp" type="image/webp"/>
              <source srcSet="/images/logos/productHunt/productHunt.png" type="image/png"/>
              <img width="180" height="42" src="/images/logos/productHunt/productHunt.png" alt="Product Hunt" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/askMen/askMen.webp" type="image/webp"/>
              <source srcSet="/images/logos/askMen/askMen.png" type="image/png"/>
              <img width="183" height="51" src="/images/logos/askMen/askMen.png" alt="AskMen" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/ampRadio/ampRadio.webp" type="image/webp"/>
              <source srcSet="/images/logos/ampRadio/ampRadio.png" type="image/png"/>
              <img width="84" height="74" src="/images/logos/ampRadio/ampRadio.png" alt="AMP Radio" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/forbes/forbes.webp" type="image/webp"/>
              <source srcSet="/images/logos/forbes/forbes.png" type="image/png"/>
              <img width="128" height="34" src="/images/logos/forbes/forbes.png" alt="Forbes" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/theHuffingtonPost/theHuffingtonPost.webp" type="image/webp"/>
              <source srcSet="/images/logos/theHuffingtonPost/theHuffingtonPost.png" type="image/png"/>
              <img width="143" height="67" src="/images/logos/theHuffingtonPost/theHuffingtonPost.png" alt="The Huffington Post" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/goodMorningAmerica/goodMorningAmerica.webp" type="image/webp"/>
              <source srcSet="/images/logos/goodMorningAmerica/goodMorningAmerica.png" type="image/png"/>
              <img width="123" height="58" src="/images/logos/goodMorningAmerica/goodMorningAmerica.png" alt="Good Morning America" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/parenting/parenting.webp" type="image/webp"/>
              <source srcSet="/images/logos/parenting/parenting.png" type="image/png"/>
              <img width="142" height="34" src="/images/logos/parenting/parenting.png" alt="Parenting" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/today/today.webp" type="image/webp"/>
              <source srcSet="/images/logos/today/today.png" type="image/png"/>
              <img width="75" height="57" src="/images/logos/today/today.png" alt="Today" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/oz/oz.webp" type="image/webp"/>
              <source srcSet="/images/logos/oz/oz.png" type="image/png"/>
              <img width="94" height="58" src="/images/logos/oz/oz.png" alt="The Dr Oz Show" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/billboard/billboard.webp" type="image/webp"/>
              <source srcSet="/images/logos/billboard/billboard.png" type="image/png"/>
              <img width="160" height="33" src="/images/logos/billboard/billboard.png" alt="Billboard" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/theView/theView.webp" type="image/webp"/>
              <source srcSet="/images/logos/theView/theView.png" type="image/png"/>
              <img width="125" height="83" src="/images/logos/theView/theView.png" alt="The View" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/steveHarvey/steveHarvey.webp" type="image/webp"/>
              <source srcSet="/images/logos/steveHarvey/steveHarvey.png" type="image/png"/>
              <img width="117" height="33" src="/images/logos/steveHarvey/steveHarvey.png" alt="Steve Harvey" loading="lazy"/>
            </picture>
            <picture>
              <source srcSet="/images/logos/nrp/nrp.webp" type="image/webp"/>
              <source srcSet="/images/logos/nrp/nrp.png" type="image/png"/>
              <img width="131" height="45" src="/images/logos/nrp/nrp.png" alt="NPR" loading="lazy"/>
            </picture>
          </div>
        </div>
      </section>

    </GenericLayout>
  );
}
